<template>
  <v-app>
    <NavBar v-if="showNavBar" />
    <DrawerView :visible="isAuthenticated" />

    <v-main class="ma-8">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import DrawerView from '@/components/DrawerView.vue';
import NavBar from '@/components/NavBar.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    DrawerView,
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    showNavBar() {
      return !this.$route.meta?.hideNavBar;
    },
  },
};
</script>

