<template>
  <v-app-bar app color="primary" dark>
    <div class="d-flex align-center">
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </div>

    <v-spacer></v-spacer>

    <v-btn v-if="!isAuthenticated" href="/signin" text>
      <span class="mr-2">Sign In</span>
    </v-btn>
    <v-btn v-if="!isAuthenticated" href="/signup" color="accent">
      <span class="mr-2">Sign Up</span>
    </v-btn>

    <v-btn v-if="isAuthenticated" text @click="logout()">
      <span class="mr-2">Sign Out</span>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: 'NavBar',
  computed: {
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated'];
    },
    title() {
      return this.$route.meta?.title || this.$route.name || '';
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
      if (this.$router.history?.current?.path !== '/signin') {
        this.$router.push('/signin');
      }
    },
  },
};
</script>
