import Vue from 'vue';
import VueRouter from 'vue-router';
import Signin from '../views/Signin.vue';
import Signup from '../views/Signup.vue';
import SignupSuccess from '../views/SignupSuccess.vue';

import { getStore } from '@bureau700/core';

const notAuthenticatedGuard = (to, from, next) => {
  try {
    const store = getStore();
    if (!store?.getters['auth/isAuthenticated']) {
      next();
      return;
    }
    next('/dashboard');
  } catch (err) {
    console.log(err);
  }
};

const authenticatedGuard = (to, from, next) => {
  try {
    const store = getStore();
    if (store?.getters['auth/isAuthenticated']) {
      next();
      return;
    }
    next('/signin');
  } catch (err) {
    console.log(err);
  }
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: { name: 'Dashboard' },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: 'about' */ '../views/Dashboard.vue'),
    beforeEnter: authenticatedGuard,
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: 'about' */ '../views/Users/Users.vue'),
    beforeEnter: authenticatedGuard,
  },
  {
    path: '/signin',
    name: 'Signin',
    component: Signin,
    beforeEnter: notAuthenticatedGuard,
    meta: {
      hideNavBar: true,
    },
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    beforeEnter: notAuthenticatedGuard,
    meta: {
      hideNavBar: true,
    },
  },
  {
    path: '/signup/:email',
    name: 'SignupSuccess',
    component: SignupSuccess,
    beforeEnter: notAuthenticatedGuard,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
