<template>
  <div class="login-container">
    <v-alert v-if="error" dense dismissible outlined text type="error" @click="error = ''">
      {{ error }}
    </v-alert>

    <Login @error="handleError" />

    <v-alert class="info-message" dense outlined type="info">
      Don't have an account ?
      <router-link to="/signup">Create an account.</router-link>
    </v-alert>
  </div>
</template>

<script>
import Login from '@/components/Login.vue';
import { AccessDeniedError } from '@bureau700/core';

export default {
  name: 'Signin',
  components: {
    Login,
  },
  data() {
    return {
      error: '',
    };
  },
  methods: {
    handleError(e) {
      if (e instanceof AccessDeniedError) {
        this.error = 'Access denied!';
      } else {
        this.error = e.message ?? e;
      }
    },
  },
};
</script>
<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.info-message {
  margin: 20px;
  width: 500px;
  display: flex;
  justify-content: center;
}
</style>
