<template>
  <v-app-bar app color="primary" dark>
    <div class="d-flex align-center">
      <router-link to="/">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />
      </router-link>
    </div>

    <v-spacer></v-spacer>

    <v-btn v-if="!isAuthenticated" href="/signin" text>
      <span class="mr-2">Sign In</span>
    </v-btn>
    <v-btn v-if="!isAuthenticated" href="/signup" color="accent">
      <span class="mr-2">Sign Up</span>
    </v-btn>

    <v-btn v-if="isAuthenticated" text @click="logout()">
      <span class="mr-2">Sign Out</span>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: 'NavBar',
  computed: {
    isAuthenticated: {
      get() {
        return this.$store.getters['auth/isAuthenticated'];
      },
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
    },
  },
};
</script>
