<template>
  <div>
    <v-navigation-drawer v-if="visible" app :mini-variant.sync="mini" permanent>
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img :src="gravatar"></v-img>
        </v-list-item-avatar>

        <v-list-item-title>{{ fullName || email }}</v-list-item-title>

        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-list dense>
        <v-list-item v-for="link in links" :key="link.icon" :to="link.route">
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import md5 from 'md5';

export default {
  name: 'DrawerView',
  props: {
    visible: Boolean,
  },
  data() {
    return {
      links: [{ icon: 'mdi-account', text: 'Users', route: '/users' }],
      mini: true,
    };
  },
  computed: {
    ...mapState('user', ['email', 'fullName']),
    gravatar() {
      return 'https://www.gravatar.com/avatar/' + (this.email ? md5(this.email) : '');
    },
  },
};
</script>
