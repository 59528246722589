import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { createStore, getApi, ApiClient } from '@bureau700/core';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.use(ApiClient, {
  baseUrl: process.env.VUE_APP_API_ENDPOINT,
});

const persistenceKey = 'vuex';
const store = createStore({
  persistenceKey,
  async rehydrated(store) {
    if (!store.state.auth.token) return;
    getApi().setToken(store.state.auth.token);
    await store.dispatch('user/fetchUser');
    if (!store.state.user.admin) {
      localStorage.removeItem(persistenceKey);
      store.dispatch('auth/logout');
    }
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
